import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { IsDefined } from '@shared/lib/Helpers';
import TableRow from '../TableRow';
const TableRowStyled = styled(TableRow, { shouldForwardProp: propName => propName !== 'isSelected' })((props) => (Object.assign({}, (props.isSelected && {
    boxShadow: '0px 1px 3px 0px #E6E8EB',
    margin: '7px 0',
    borderRadius: props.theme.shape.borderRadius,
}))));
const TableBodyRow = ({ tableApi, row, CellComponent, children = undefined, className = '', onHover = () => { }, }) => (_jsxs(TableRowStyled, { component: 'div', className: className, isSelected: row.getIsSelected(), onMouseOver: () => onHover(row.index, row.original, true), onMouseOut: () => onHover(row.index, row.original, false), children: [IsDefined(children) &&
            children, !IsDefined(children) &&
            row.getVisibleCells().map(cell => (_jsx(CellComponent, { tableApi: tableApi, cell: cell }, cell.id)))] }));
export default TableBodyRow;
