import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TableBody as MuiTableBody } from '@mui/material';
import { useVirtual } from 'react-virtual';
import { useMemo } from 'react';
import { IsDefined } from '@shared/lib/Helpers';
const TableBody = ({ tableApi, RowComponent, CellComponent, TableContainerRef, children = undefined, onRowHover = () => { }, }) => {
    const { rows } = tableApi.getRowModel();
    const rowsVirtualizer = useVirtual({
        parentRef: TableContainerRef,
        size: rows.length,
        overscan: 20,
    });
    const { virtualItems: virtualRows, totalSize } = rowsVirtualizer;
    const paddingTop = useMemo(() => { var _a; return (virtualRows.length > 0 ? ((_a = virtualRows === null || virtualRows === void 0 ? void 0 : virtualRows[0]) === null || _a === void 0 ? void 0 : _a.start) || 0 : 0); }, [virtualRows]);
    const paddingBottom = useMemo(() => {
        var _a;
        return (virtualRows.length > 0 ?
            totalSize - (((_a = virtualRows === null || virtualRows === void 0 ? void 0 : virtualRows[virtualRows.length - 1]) === null || _a === void 0 ? void 0 : _a.end) || 0) :
            0);
    }, [virtualRows, totalSize]);
    return (_jsxs(MuiTableBody, { component: 'div', children: [IsDefined(children) &&
                children, !IsDefined(children) && (_jsxs(_Fragment, { children: [paddingTop > 0 && (_jsx("div", { style: { height: paddingTop } })), virtualRows.map(virtualRow => {
                        const row = rows[virtualRow.index];
                        return (_jsx(RowComponent, { tableApi: tableApi, row: row, CellComponent: CellComponent, onHover: onRowHover }, row.id));
                    }), paddingBottom > 0 && (_jsx("div", { style: { height: paddingBottom } }))] }))] }));
};
export default TableBody;
