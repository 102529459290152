import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TableCell as MuiTableCell } from '@mui/material';
import { flexRender } from '@tanstack/react-table';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { IsDefined } from '@shared/lib/Helpers';
const CellStyled = styled(MuiTableCell, {
    shouldForwardProp: propName => propName !== 'isSelected' &&
        propName !== 'isPreviousToSelected' &&
        propName !== 'isFirstCell' &&
        propName !== 'isLastCell' &&
        propName !== 'padding',
})(props => (Object.assign(Object.assign(Object.assign(Object.assign({ display: 'flex', alignItems: 'center', padding: props.padding || '0 8px', overflow: 'hidden' }, (props.isSelected && {
    paddingLeft: props.theme.spacing_sizes.xs,
    borderTop: `1px solid ${props.theme.customColors.surface.outline}`,
    borderBottom: `1px solid ${props.theme.customColors.surface.outline}`,
})), (props.isPreviousToSelected && {
    borderBottom: 'none',
})), (props.isSelected && props.isFirstCell && {
    borderBottomLeftRadius: props.theme.shape.borderRadius,
    borderTopLeftRadius: props.theme.shape.borderRadius,
    borderLeft: '8px solid var(--main-5, #6488F0)',
})), (props.isSelected && props.isLastCell && {
    borderBottomRightRadius: props.theme.shape.borderRadius,
    borderTopRightRadius: props.theme.shape.borderRadius,
    borderRight: `1px solid ${props.theme.customColors.surface.outline}`,
}))));
const CellDivComponent = (props) => (
// eslint-disable-next-line react/jsx-props-no-spreading
_jsx("div", Object.assign({}, props)));
const TableBodyCell = ({ tableApi, cell, className = undefined, children = undefined, }) => {
    var _a, _b, _c, _d;
    const isPreviousToSelected = useMemo(() => {
        let result = false;
        if (!cell.row.getIsSelected() && tableApi.getIsSomePageRowsSelected()) {
            const { rows } = tableApi.getSelectedRowModel();
            rows.forEach(r => {
                if (cell.row.index - r.index === -1) {
                    result = true;
                }
            });
        }
        return result;
    }, [cell, tableApi]);
    const isFirstCell = useMemo(() => {
        const cells = cell.row.getAllCells();
        return cells.length > 0 && cells[0].id === cell.id;
    }, [cell]);
    const isLastCell = useMemo(() => {
        const cells = cell.row.getAllCells();
        return cells.length > 0 && cells[cells.length - 1].id === cell.id;
    }, [cell]);
    return (_jsxs(CellStyled, { className: className, component: CellDivComponent, isSelected: cell.row.getIsSelected(), isPreviousToSelected: isPreviousToSelected, isFirstCell: isFirstCell, isLastCell: isLastCell, align: ((_a = cell.column.columnDef.meta) === null || _a === void 0 ? void 0 : _a.align) || 'left', padding: (_b = cell.column.columnDef.meta) === null || _b === void 0 ? void 0 : _b.padding, style: {
            flex: ((_c = cell.column.columnDef.meta) === null || _c === void 0 ? void 0 : _c.flex) || 1,
            overflow: ((_d = cell.column.columnDef.meta) === null || _d === void 0 ? void 0 : _d.overflow) || 'hidden',
        }, children: [IsDefined(children) &&
                children, !IsDefined(children) &&
                flexRender(cell.column.columnDef.cell, cell.getContext())] }));
};
export default TableBodyCell;
